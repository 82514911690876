import { PropsWithChildren, createContext, useContext, useMemo } from "react";
import { Config } from "@/graphql/generated/graphql";

const ConfigContext = createContext<Config>({} as Config);

export const useConfig = (): Config => {
  const value = useContext(ConfigContext);
  return value;
};

type Props = {
  config: Config;
};

export const ConfigProvider: React.FC<PropsWithChildren<Props>> = ({ config, children }) => {
  const value = useMemo(() => config, [config]);

  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
};
