"use client";

import styled from "@emotion/styled";
import Image from "next/image";
import ReactMarkdown, { Options, Components } from "react-markdown";

// https://amirardalan.com/blog/use-next-image-with-react-markdown
const components: Components = {
  p: props => {
    const { node } = props;

    // https://github.com/remarkjs/react-markdown/issues/711
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const child: any = node?.children[0];

    if (child.value === "---") {
      return <hr />;
    }

    if (child?.tagName === "img") {
      const metastring = child.properties.alt;
      const alt = metastring?.replace(/ *\{[^)]*\} */g, "");
      const metaWidth = metastring.match(/{([^}]+)x/);
      const metaHeight = metastring.match(/x([^}]+)}/);
      const width = metaWidth ? metaWidth[1] : "600";
      const height = metaHeight ? metaHeight[1] : "400";

      return (
        <div>
          <Image
            src={child.properties.src}
            width={width}
            height={height}
            alt={alt}
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
          />
        </div>
      );
    }

    return <p>{props.children}</p>;
  },
};

const CustomReactMarkdown: React.FC<Options> = props => <ReactMarkdown components={components} {...props} />;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const Markdown = styled(CustomReactMarkdown)`
  * + * {
    margin-top: 1em;
  }

  li + li {
    margin-top: 0;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  ul,
  ol {
    margin-left: 1em;
  }

  ul ul {
    list-style-type: circle;
  }

  ul ul,
  ul ol,
  ol ol,
  ol ul {
    margin-left: 2em;
  }

  li:has(ul),
  li:has(ol) {
    list-style: none;
  }

  a {
    color: #2b504a;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    word-break: break-word;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }

  h1,
  h2,
  h3 {
    font-size: 1.5em;
  }

  h4 {
    font-size: 1.3em;
  }

  h5 {
    font-size: 1.2em;
  }
`;

export default Markdown;
